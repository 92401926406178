import React from 'react';
import styles from './HeroSection.module.css';
import arrowRight from '../../assets/arrow-right.svg';
import introVideo from '../../assets/SysGen-Intro.mp4';  // Ensure this path is correct

const HeroSection = ({ onAuthOpen }) => {
  return (
    <section className={styles.heroSection} id="top">
      <video className={styles.introVideo} src={introVideo} controls />
      <div className={styles.heroContent}>
        <h1>For the Business Side of Ministry</h1>
        <p>ksdfj ifj ierweirwier owier ier owei i ier ieroer e oi wer wwerwerwer wer wer wej 9i oi oi ddfg ert ert er er ert ert ret er erty er ery e.</p>
        <div className={styles.heroButtons}>
          <a 
            href="#about" 
            className={styles.getStarted}
            onClick={(e) => { 
              e.preventDefault(); // Prevent the default anchor behavior
              onAuthOpen(); // Trigger authentication flow
            }}
          >
            Try it for free <img src={arrowRight} alt="Arrow Right" />
          </a>
          <a 
            href="#purchase" 
            className={styles.purchaseNow}
            onClick={(e) => { 
              e.preventDefault(); // Prevent the default anchor behavior
              onAuthOpen(); // Trigger authentication flow
            }}
          >
            or purchase now <img src={arrowRight} alt="Arrow Right" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;