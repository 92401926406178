import React from 'react';
import { Box, Typography, Grid, Tooltip, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import VirtualBAIcon from '../../assets/AppIcons/Virtual BA.svg';
import virtualHelpDeskIcon from '../../assets/AppIcons/Virtual Help Desk.svg';
import taggerIcon from '../../assets/AppIcons/Tagger.svg';
import contentSpecialistIcon from '../../assets/AppIcons/Content Specialist.svg';
import colleagueCreatorIcon from '../../assets/AppIcons/Colleague Creator.svg';
import ScopeEngineerIcon from '../../assets/AppIcons/Scope Engineer.svg';
import roundTableIcon from '../../assets/AppIcons/Round Table.svg';
import dbWorksIcon from '../../assets/AppIcons/DB Works.svg';
import findingSolutionsIcon from '../../assets/AppIcons/Finding Solutions.svg';
import intelModEngineeringIcon from '../../assets/AppIcons/Intel Mod Endineering.svg';
import modelTrainingIcon from '../../assets/AppIcons/Model Training.svg';
import presenterIcon from '../../assets/AppIcons/Presenter.svg';
import promptEngineeringIcon from '../../assets/AppIcons/Prompt Engineering.svg';
import qaStationIcon from '../../assets/AppIcons/QA Station.svg';
import schemaEngineeringIcon from '../../assets/AppIcons/Schema Engineering.svg';
import studioIcon from '../../assets/AppIcons/Studio.svg';
import virtualDevIcon from '../../assets/AppIcons/Virtual Dev.svg';
import googleDeepDiveIcon from '../../assets/AppIcons/Google Deep Dive.svg';

const AppPanelContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '160px',
  left: theme.spacing(45),
  zIndex: theme.zIndex.drawer + 2,
  width: '1200px', // Match the width specified in WorkspaceLayout.js
  backgroundColor: '#e0e0e0',
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  padding: 0, // Remove padding for full-width header bar
  overflow: 'hidden',
  maxHeight: 'calc(100vh - 100px)', // Set the maximum height to fill the viewport minus some padding
  display: 'flex',
  flexDirection: 'column',
}));

const HeaderBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2), // Smaller height, full-width padding
  backgroundColor: 'rgb(83, 83, 83)', // Darker color
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  width: '100%', // Make it span full width
  boxSizing: 'border-box', // Include padding and border in the element's total width and height
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  padding: theme.spacing(2),
  flexGrow: 1,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 0, // Make the hover shape square
  padding: 6,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Lighter hover effect
  },
}));

const AppPanel = ({ addAppToNavbar, onClose }) => {
  const theme = useTheme();

  const apps = [
    { id: 'Virtual BA', name: 'Virtual BA', icon: VirtualBAIcon },
    { id: 'Virtual Help Desk', name: 'Virtual Help Desk', icon: virtualHelpDeskIcon },
    { id: 'Tagger', name: 'Tagger', icon: taggerIcon },
    { id: 'Content Specialist', name: 'Content Specialist', icon: contentSpecialistIcon },
    { id: 'Colleague Creator', name: 'Colleague Creator', icon: colleagueCreatorIcon },
    { id: 'Scope Engineer', name: 'Scope Engineer', icon: ScopeEngineerIcon },
    { id: 'Round Table', name: 'Round Table', icon: roundTableIcon },
    { id: 'DB Works', name: 'DB Works', icon: dbWorksIcon },
    { id: 'Finding Solutions', name: 'Finding Solutions', icon: findingSolutionsIcon },
    { id: 'Intel Mod Engineering', name: 'Intel Mod Engineering', icon: intelModEngineeringIcon },
    { id: 'Model Training', name: 'Model Training', icon: modelTrainingIcon },
    { id: 'Presenter', name: 'Presenter', icon: presenterIcon },
    { id: 'Prompt Engineering', name: 'Prompt Engineering', icon: promptEngineeringIcon },
    { id: 'QA Station', name: 'QA Station', icon: qaStationIcon },
    { id: 'Schema Engineering', name: 'Schema Engineering', icon: schemaEngineeringIcon },
    { id: 'Studio', name: 'Studio', icon: studioIcon },
    { id: 'Virtual Dev', name: 'Virtual Dev', icon: virtualDevIcon },
    { id: 'Google Deep Dive', name: 'Google Deep Dive', icon: googleDeepDiveIcon },
  ];

  return (
    <AppPanelContainer theme={theme}>
      <HeaderBar>
        <Typography variant="h6" style={{ color: '#ffffff' }}>
          Select an app to add to the Navbar
        </Typography>
        <StyledIconButton onClick={onClose}>
          <CloseIcon style={{ color: '#ffffff' }} />
        </StyledIconButton>
      </HeaderBar>
      <ContentContainer>
        <Grid container spacing={2}>
          {apps.map(app => (
            <Grid item xs={3} key={app.id}>
              <Tooltip title={app.name} placement="top" arrow>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    cursor: 'pointer',
                    padding: 1,
                    borderRadius: 1,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  onClick={() => addAppToNavbar(app)}
                >
                  <img src={app.icon} alt={app.name} style={{ width: 60, height: 60, marginBottom: 8 }} />
                  <Typography variant="body2">{app.name}</Typography>
                </Box>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </ContentContainer>
    </AppPanelContainer>
  );
};

export default AppPanel;