// src/components/ContactSection.js
import React from 'react';
import styles from './ContactSection.module.css';

const ContactSection = () => {
  return (
    <section className={styles.contactSection} id="contact">
      <h2>Contact Us</h2>
      <p>
        Reach out to us for any inquiries or support. We are here to help you achieve your business goals.
      </p>
      <form className={styles.contactForm}>
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <input type="text" placeholder="Subject" required />
        <input type="text" placeholder="Your Company (optional)" />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit">Send Message</button>
      </form>
    </section>
  );
};

export default ContactSection;
