// src/theme.js
import { createTheme } from '@mui/material/styles';

const navbarDrawerWidth = 340; // Original width 240 + 100
const navbarMiniDrawerWidth = 60;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#64b5f6',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          transition: 'width 0.3s ease-in-out',
          overflowX: 'hidden',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          transition: 'padding 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Light gray background on hover
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1rem', // Adjust font size
          backgroundColor: '#333', // Dark background color for contrast
          color: '#fff', // White text color for readability
        },
      },
    },
  },
  navbarDrawerWidth,
  navbarMiniDrawerWidth,
});

export default darkTheme;