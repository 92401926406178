import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Slider, Paper, Grid } from '@mui/material';

const AISettingsPanel = () => {
  const theme = useTheme(); // Access the theme
  const [maxTokens, setMaxTokens] = useState(2760);
  const [temperature, setTemperature] = useState(0.8);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [topP, setTopP] = useState(1);

  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };

  return (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Paper elevation={3} sx={{ flex: 1, p: 2, borderRadius: 2, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box>
            <Typography gutterBottom sx={{ fontSize: '0.875rem', ml: 0, color: theme.palette.text.primary }}>Max Tokens</Typography>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Slider
                  size="small"
                  value={maxTokens}
                  onChange={handleSliderChange(setMaxTokens)}
                  min={0}
                  max={4095}
                  step={1}
                  aria-labelledby="max-tokens-slider"
                  sx={{ width: '100%', color: theme.palette.primary.main }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: '0.875rem', mb: 1, textAlign: 'center', color: theme.palette.text.primary }}>{maxTokens}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography gutterBottom sx={{ fontSize: '0.875rem', ml: 0, color: theme.palette.text.primary }}>Temperature</Typography>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Slider
                  size="small"
                  value={temperature}
                  onChange={handleSliderChange(setTemperature)}
                  min={0}
                  max={2}
                  step={0.01}
                  aria-labelledby="temperature-slider"
                  sx={{ width: '100%', color: theme.palette.primary.main }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: '0.875rem', mb: 1, textAlign: 'center', color: theme.palette.text.primary }}>{temperature.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography gutterBottom sx={{ fontSize: '0.875rem', ml: 0, color: theme.palette.text.primary }}>Presence Penalty</Typography>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Slider
                  size="small"
                  value={presencePenalty}
                  onChange={handleSliderChange(setPresencePenalty)}
                  min={0}
                  max={2}
                  step={0.01}
                  aria-labelledby="presence-penalty-slider"
                  sx={{ width: '100%', color: theme.palette.primary.main }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: '0.875rem', mb: 1, textAlign: 'center', color: theme.palette.text.primary }}>{presencePenalty.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography gutterBottom sx={{ fontSize: '0.875rem', ml: 0, color: theme.palette.text.primary }}>Frequency Penalty</Typography>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Slider
                  size="small"
                  value={frequencyPenalty}
                  onChange={handleSliderChange(setFrequencyPenalty)}
                  min={0}
                  max={2}
                  step={0.01}
                  aria-labelledby="frequency-penalty-slider"
                  sx={{ width: '100%', color: theme.palette.primary.main }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: '0.875rem', mb: 1, textAlign: 'center', color: theme.palette.text.primary }}>{frequencyPenalty.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Typography gutterBottom sx={{ fontSize: '0.875rem', ml: 0, color: theme.palette.text.primary }}>Top P</Typography>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Slider
                  size="small"
                  value={topP}
                  onChange={handleSliderChange(setTopP)}
                  min={0}
                  max={1}
                  step={0.01}
                  aria-labelledby="top-p-slider"
                  sx={{ width: '100%', color: theme.palette.primary.main }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: '0.875rem', mb: 1, textAlign: 'center', color: theme.palette.text.primary }}>{topP.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default AISettingsPanel;