import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  //Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/ChevronLeft';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useTheme } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import AppPanel from '../AppPanel/AppPanel';
import AppBase from '../Shared/AppBase';
import AICommExtended from '../Shared/AICommExtended';
import AISettingsPanel from '../Shared/AISettingsPanel';
import DocEditor from '../Shared/DocEditor';
//import { Logout } from '@mui/icons-material';

// Import icons from the AppIcons folder
//import ColleagueCreatorIcon from '../../assets/AppIcons/Colleague Creator.svg';
//import ContentSpecialistIcon from '../../assets/AppIcons/Content Specialist.svg';
//import DBWorksIcon from '../../assets/AppIcons/DB Works.svg';
//import FindingSolutionsIcon from '../../assets/AppIcons/Finding Solutions.svg';
//import GoogleDeepDiveIcon from '../../assets/AppIcons/Google Deep Dive.svg';
//import IntelModEngineeringIcon from '../../assets/AppIcons/Intel Mod Endineering.svg';
//import ModelTrainingIcon from '../../assets/AppIcons/Model Training.svg';
//import PresenterIcon from '../../assets/AppIcons/Presenter.svg';
//import PromptEngineeringIcon from '../../assets/AppIcons/Prompt Engineering.svg';
//import QAStationIcon from '../../assets/AppIcons/QA Station.svg';
//import RoundTableIcon from '../../assets/AppIcons/Round Table.svg';
//import SchemaEngineeringIcon from '../../assets/AppIcons/Schema Engineering.svg';
//import ScopeEngineerIcon from '../../assets/AppIcons/Scope Engineer.svg';
//import StudioIcon from '../../assets/AppIcons/Studio.svg';
//import TaggerIcon from '../../assets/AppIcons/Tagger.svg';
import VirtualBAIcon from '../../assets/AppIcons/Virtual BA.svg';
//import VirtualDevIcon from '../../assets/AppIcons/Virtual Dev.svg';
//import VirtualHelpDeskIcon from '../../assets/AppIcons/Virtual Help Desk.svg';

const WorkspaceLayout = () => {
  const theme = useTheme();
  //const [isDrawerOpen, setDrawerOpen] = useState(true);

  // Use a temporary placeholder value
  const isDrawerOpen = true;

  const [isNavbarExpanded, setNavbarExpanded] = useState(false);
  const [isAppPanelVisible, setAppPanelVisible] = useState(false);
  const [navbarApps, setNavbarApps] = useState([]);
  const [activeAppConfig, setActiveAppConfig] = useState(null); // New state for active app config

  const navbarDrawerWidth = 340;
  const navbarMiniDrawerWidth = 60;

  //const toggleDrawer = () => {
  //  setDrawerOpen(!isDrawerOpen);
  //};

  const toggleNavbar = () => {
    setNavbarExpanded(!isNavbarExpanded);
  };

  const toggleAppPanel = () => {
    setAppPanelVisible(!isAppPanelVisible);
  };

  const addAppToNavbar = (app) => {
    setNavbarApps((prevApps) => {
      if (prevApps.some((existingApp) => existingApp.id === app.id)) {
        return prevApps;
      }
      return [...prevApps, app];
    });
    console.log('App added to navbar:', app); 
  };

  const removeAppFromNavbar = (appId) => {
    setNavbarApps((prevApps) => prevApps.filter((app) => app.id !== appId));
    console.log('App removed from navbar:', appId); 
  };

  const minimizeApp = () => {
    console.log('App minimized'); 
  };

  const closeApp = () => {
    console.log('App closed'); 
    setActiveAppConfig(null);  // Reset active app on close 
  };

  const startApp = (appId) => {
    console.log('startApp called with appId:', appId);
    const appConfig = appConfigurations.find(config => config.id === appId);
    console.log('AppConfig found:', appConfig);
    setActiveAppConfig(appConfig);
  };

  const appConfigurations = [
    {
      id: 'Virtual BA',
      title: 'Virtual BA',
      icon: VirtualBAIcon,
      headerContent: <Typography variant="body1">Header Content 1</Typography>,
      //footerContent: <Typography variant="body1" sx={{ color: 'white', backgroundColor: 'blue', padding: 1 }}>Footer Content 1</Typography>,
      panelCount: 3,
      panelOrientation: 'horizontal',
      panelSizes: [17, 53, 40],
      panelMinSizes: [17, 30, 30],
      enablePanelResizing: true,
      showPanelBorders: false,
      panelBackgroundColors: ['#282828', '#282828', '#282828'],
      //panelBorders: ['2px dashed red', '2px dashed green', '2px dashed blue', '2px dashed orange'],
      panelPadding: ['6px', '0px', '0px', '0px'],
      panelMargins: ['0px', '0px', '0px', '0px'], // Add panelMargins here
      panelIds: ['panel1', 'panel2', 'panel3'], // Add panelIds here
      panelDirection: ['column', 'column', 'column'], // Add panelDirection here
      panelContent: [
        [
          <Box key="p1c1" sx={{ flex: 1, backgroundColor: 'rgba(255, 0, 0, 0.2)' }}></Box>,
          <Box key="p1c2" sx={{ flex: 1, backgroundColor: 'rgba(0, 0, 255, 0.2)' }}></Box>,         
          //<Box key="p1c3" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1 }}><AISettingsPanel /></Box>,
          <Box key="p3c1" sx={{ width: '100%', height: '100%' }}><AISettingsPanel/></Box>,
        ],
        [
          <Box key="p2c1" sx={{ flex: 1 }}><AICommExtended /></Box>,
        ],
        [
          <Box key="p3c1" sx={{ width: '100%', height: '100%' }}><DocEditor /></Box>,
        ],
      ], // Add panelContent here
      stackSpacing: [3, 2, 2], // Add stackSpacing here
      stackSizes: [
        [1, 1, .5], // Sizes for items in panel 1
        [1], // Sizes for items in panel 2
        [1], // Sizes for items in panel 3      
      ], // Add stackSizes here
      panelClassNames: ['panel-class-1', 'panel-class-2', 'panel-class-3'], // Add panelClassNames here
      onError: (error, errorInfo) => {
        console.error('Error in AppBase:', error, errorInfo);
      },      
    },

    // Add more app configurations as needed
  ];

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden', flexDirection: 'column' }}>
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleAppPanel} sx={{ marginRight: 2 }}>
            <MenuIcon /> 
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img src={logo} alt="SysGen Logo" style={{ height: 50, marginRight: 20 }} />
 
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flexGrow: 1, marginTop: '64px' }}>
        <Drawer
          variant="permanent"
          open={isDrawerOpen}
          sx={{
            width: isDrawerOpen ? (isNavbarExpanded ? navbarDrawerWidth : navbarMiniDrawerWidth) : navbarMiniDrawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: isDrawerOpen ? (isNavbarExpanded ? navbarDrawerWidth : navbarMiniDrawerWidth) : navbarMiniDrawerWidth,
              top: 64,
              height: 'calc(100% - 64px)',
              transition: 'width 0.3s',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 1, transition: 'padding 0.3s ease-in-out' }}>
            <IconButton onClick={toggleNavbar}>
              {isNavbarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon /> }
            </IconButton>
          </Box>
          <List sx={{ transition: 'all 0.3s ease-in-out' }}>
            {navbarApps.map((app) => (
              <ListItem key={app.id} onClick={() => startApp(app.id)}>
                <ListItemIcon>
                  <img src={app.icon} alt={app.name} style={{ width: 32, height: 32 }} />
                </ListItemIcon>
                <ListItemText primary={app.name} />
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={(e) => { e.stopPropagation(); removeAppFromNavbar(app.id); }}
                  sx={{
                    color: theme.palette.error.main,
                    '&:hover': {
                      color: theme.palette.error.dark,
                    },
                  }}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: 'calc(100%)',
            marginLeft: 0,
            transition: 'margin 0.3s, width 0.3s',
            overflow: 'auto',
          }}
        >
          {activeAppConfig && (
            <AppBase
              key={activeAppConfig.id}
              title={activeAppConfig.title}
              icon={activeAppConfig.icon}
              headerContent={activeAppConfig.headerContent}
              footerContent={activeAppConfig.footerContent}
              panelCount={activeAppConfig.panelCount}
              panelOrientation={activeAppConfig.panelOrientation}
              panelSizes={activeAppConfig.panelSizes}
              panelMinSizes={activeAppConfig.panelMinSizes}
              enablePanelResizing={activeAppConfig.enablePanelResizing}
              showPanelBorders={activeAppConfig.showPanelBorders}
              panelBackgroundColors={activeAppConfig.panelBackgroundColors}
              panelBorders={activeAppConfig.panelBorders}
              panelPadding={activeAppConfig.panelPadding}
              panelMargins={activeAppConfig.panelMargins}
              panelIds={activeAppConfig.panelIds}
              panelDirection={activeAppConfig.panelDirection}
              panelContent={activeAppConfig.panelContent}
              stackSpacing={activeAppConfig.stackSpacing}
              stackSizes={activeAppConfig.stackSizes}
              panelClassNames={activeAppConfig.panelClassNames}
              onError={activeAppConfig.onError}
              initialState={activeAppConfig.initialState}
              minimizeApp={minimizeApp}
              closeApp={closeApp}
              isDrawerOpen={isDrawerOpen}
              isNavbarExpanded={isNavbarExpanded}
            />
          )}
        </Box>
      </Box>
      {isAppPanelVisible && isDrawerOpen && (
        <AppPanel
          addAppToNavbar={addAppToNavbar}
          onClose={toggleAppPanel}
          customStyles={{
            position: 'fixed',
            top: 64,
            left: 16,
            width: `calc(100% - 32px)`,
            height: 'calc(100% - 64px)',
          }}
        />
      )}
    </Box>
  );
};

export default WorkspaceLayout;