import React from 'react';
import PricingCard from './PricingCard';
import InfoCard from './InfoCard';
import styles from './PricingSection.module.css';

const PricingSection = ({ onBuyNow }) => {
  const pricingTiers = [
    {
      id: 1,
      title: 'Test Drive',
      price: '$0',
      billing: '$0 billed annually',
      buttonText: 'Buy now',
      units: '0 Processing units',
      features: [
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
      ],
    },
    {
      id: 2,
      title: 'Essential',
      price: '$0',
      billing: '$0 billed annually',
      buttonText: 'Buy now',
      units: '0 Processing units',
      features: [
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
      ],
    },
    {
      id: 3,
      title: 'Advanced',
      price: '$0',
      billing: '$0 billed annually',
      buttonText: 'Buy now',
      units: '0 Processing units',
      features: [
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
      ],
    },
    {
      id: 4,
      title: 'Researcher',
      price: '$0',
      billing: '$0 billed annually',
      buttonText: 'Buy now',
      units: '0 Processing units',
      features: [
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
        'Bulleted text here',
      ],
    },
    {
      id: 5,
      title: 'Enterprise',
      price: 'Custom Pricing',
      billing: '',
      buttonText: 'Learn More',
      units: '',
      features: [
        'Custom Pricing',
        'SSO',
        'Company-Assigned Account Manager',
        'Easily manage your account online',
        'Granular Allocation of Processing Units per employee',
        'Detailed Usage Reports',
      ],
    },
  ];

  const infoCardContent = {
    title: 'No Tokens Left Behind!',
    paragraphs: [
      "When selecting a tier, consider the number of Processing Units (Units) you'll need. Some applications within our product are more process-intensive and may require more Units. By choosing a higher tier, you'll ensure you have enough Units to fully utilize all the features and capabilities that the product offers.",
      'Any unused Processing Units at the end of the month will carry over to the next month. With SysGen, you only pay for what you use.'
    ]
  };

  return (
    <section className={styles.pricingSection} id="pricing">
      <div className={styles.wrapper}>
        <div className={styles.textAndCards}>
          <div className={styles.textAlignContent}>
            <InfoCard title={infoCardContent.title} paragraphs={infoCardContent.paragraphs} />
          </div>
          <div className={styles.pricingCardsWrapper}>
            <div className={styles.pricingCardsContainer}>
              <div className={styles.starTextContainer}>
                <span className={styles.globalStar}>★</span>
                <span className={styles.starText}>Companies should take advantage of our Enterprise tier!</span>
              </div>
              {pricingTiers.map((tier) => (
                <PricingCard key={tier.id} {...tier} onBuyNow={onBuyNow} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
