import { configureStore } from '@reduxjs/toolkit';
import aiConversationReducer from './features/aiConversationSlice';
import appReducer from './features/appSlice';
import documentReducer from './features/documentSlice';

const store = configureStore({
  reducer: {
    aiConversation: aiConversationReducer,
    app: appReducer,
    document: documentReducer, // Add the document slice here
  },
});

export default store;