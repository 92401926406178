import React, { useState, useEffect } from 'react';
import { Descope } from '@descope/react-sdk';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import logo from '../../assets/SysGenBlackLargeTagline.png'; // Adjust the path to your logo file

const AuthPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPurchase, setIsPurchase] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    if (location.state && location.state.isPurchase) {
      setIsPurchase(true);
    }
  }, [location.state]);

  const handleSuccess = async (e) => {
    // Extract JWT from sessionJwt
    const jwt = e.detail.sessionJwt;

    // Log the JWT
    if (jwt) {
      console.log('JWT:', jwt);
    } else {
      console.error('JWT not found in the response');
    }

    // Simplified API Call for Testing (Temporary Test Code)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwt}`,
      };

      const body = JSON.stringify({ message: e.detail.user });

      console.log('Headers:', headers);
      console.log('Body:', body);

      console.log('Attempting to call API for testing');
      const response = await fetch('https://b8d8n9f3kb.execute-api.us-east-1.amazonaws.com/prod/authorize', {
        method: 'POST',
        headers: headers,
        body: body,
      });

      console.log('API call made, awaiting response...');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Data from Lambda:', data);
      setApiResponse(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setApiResponse({ error: error.message });
    }

    // Redirect to the purchase page if the user came from a "Buy Now" button
    if (isPurchase) {
      navigate('/purchase');
    } else {
      navigate('/workspace');
    }
  };

  const handleError = (e) => {
    console.log('Could not log in!', e);
  };

  const handleLogoClick = () => {
    navigate('/'); // This navigates to the landing page ("/")
  };

  return (
    <Box sx={{ position: 'relative', height: '100vh', backgroundColor: '#ffffff' }}>
      <Box sx={{ position: 'absolute', top: 15, left: 16, cursor: 'pointer' }} onClick={handleLogoClick}>
        <img src={logo} alt="Logo" style={{ height: 34 }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', height: '100%' }}>
        <Box sx={{ padding: 2, ml: 0, mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'initial', width: 400, maxWidth: '800px' }}>
          <Typography component="h1" variant="h5" sx={{ marginLeft: 5, marginBottom: 1 }}>
            Sign In or Sign Up
          </Typography>
          <Descope
            flowId="sign-up-or-in"
            onSuccess={handleSuccess}
            onError={handleError}
            style={{ width: '100%' }} // Ensure the Descope component fits within the container
          />
          {/* Display the API response for testing purposes */}
          {apiResponse && (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography component="h2" variant="h6">API Response:</Typography>
              <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AuthPage;