import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeApps: [],
  minimizedApps: [],
};

const appSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    addApp: (state, action) => {
      state.activeApps.push(action.payload);
    },
    minimizeApp: (state, action) => {
      const app = state.activeApps.find(app => app.id === action.payload);
      if (app) {
        state.activeApps = state.activeApps.filter(app => app.id !== action.payload);
        state.minimizedApps.push(app);
      }
    },
    closeApp: (state, action) => {
      state.activeApps = state.activeApps.filter(app => app.id !== action.payload);
      state.minimizedApps = state.minimizedApps.filter(app => app.id !== action.payload);
    },
    restoreApp: (state, action) => {
      const app = state.minimizedApps.find(app => app.id === action.payload);
      if (app) {
        state.minimizedApps = state.minimizedApps.filter(app => app.id !== action.payload);
        state.activeApps.push(app);
      }
    },
  },
});

export const { addApp, minimizeApp, closeApp, restoreApp } = appSlice.actions;

export default appSlice.reducer;
