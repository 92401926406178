import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Box, Dialog, Snackbar, Paper, Typography, IconButton, Tooltip } from '@mui/material';
import { setContent, setZoomLevel, undo, redo } from '../../features/documentSlice';
import ReactMarkdown from 'react-markdown';
import DOMPurify from 'dompurify';
import CodeMirror from '@uiw/react-codemirror';
import { markdown } from '@codemirror/lang-markdown';
import { javascript } from '@codemirror/lang-javascript';
import { xml } from '@codemirror/lang-xml';
import { html } from '@codemirror/lang-html';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

// Import createTheme and tags for custom theming
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';

// Import copy and paste icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

// Create custom theme
const customTheme = createTheme({
  theme: 'dark',
  settings: {
    background: '#515151', // Hardcoded background color
    foreground: '#ffffff',
    caret: 'black',
    selection: 'rgba(0, 0, 0, 0.1)',
    lineHighlight: 'rgba(0, 0, 0, 0.05)',
  },
  styles: [
    { tag: t.keyword, color: 'blue' },
    { tag: t.comment, color: 'green' },
    { tag: t.string, color: 'red' },
    { tag: t.variableName, color: 'purple' },
    // Add other styling rules here if needed
  ]
});

const DocEditor = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const content = useSelector((state) => state.document.content);
  const fileType = useSelector((state) => state.document.fileType);
  const zoomLevel = useSelector((state) => state.document.zoomLevel);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [justification, setJustification] = useState('left');  // Default to left justification

  // Copy content to clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setError('Content copied to clipboard');
    } catch (err) {
      setError('Failed to copy content');
    }
  };

  // Paste content from clipboard
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      dispatch(setContent({ content: content + text, fileType }));
    } catch (err) {
      setError('Failed to read from clipboard');
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setContent({ content: reader.result, fileType: file.type }));
        setOpen(false);
      };
      reader.onerror = () => {
        setError('Failed to read file');
      };
      reader.readAsText(file);
    } else {
      setError('File size exceeds 5MB');
    }
  };

  const handleSave = () => {
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `document.${fileType.split('/')[1]}`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleZoom = (direction) => {
    const newZoomLevel = direction === 'in' ? zoomLevel + 10 : zoomLevel - 10;
    if (newZoomLevel >= 50 && newZoomLevel <= 200) {
      dispatch(setZoomLevel(newZoomLevel));
    }
  };

  const handleJustification = (direction) => {
    setJustification(direction);
  };

  const extensions = [
    customTheme, // Apply custom theme
    fileType === 'text/markdown' ? markdown() : null,
    fileType === 'application/javascript' ? javascript() : null,
    fileType === 'application/xml' ? xml() : null,
    fileType === 'text/html' ? html() : null,
  ].filter(Boolean);

  return (
    <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default,
          padding: 1,
          borderRadius: 1,
        }}
      >
        <Box>
          <Tooltip title="Upload File">
            <IconButton color="primary" onClick={() => setOpen(true)}>
              <UploadFileIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save File">
            <IconButton color="primary" onClick={handleSave}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Copy">
            <IconButton color="primary" onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Paste">
            <IconButton color="primary" onClick={handlePaste}>
              <ContentPasteIcon />
            </IconButton>
          </Tooltip>
        </Box>        
        <Box>
          <Tooltip title="Undo">
            <IconButton color="primary" onClick={() => dispatch(undo())}>
              <UndoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo">
            <IconButton color="primary" onClick={() => dispatch(redo())}>
              <RedoIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Zoom In">
            <IconButton color="primary" onClick={() => handleZoom('in')}>
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom Out">
            <IconButton color="primary" onClick={() => handleZoom('out')}>
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title="Align Left">
            <IconButton color="primary" onClick={() => handleJustification('left')}>
              <FormatAlignLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Center">
            <IconButton color="primary" onClick={() => handleJustification('center')}>
              <FormatAlignCenterIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Align Right">
            <IconButton color="primary" onClick={() => handleJustification('right')}>
              <FormatAlignRightIcon />
            </IconButton>
          </Tooltip>
        </Box>
       
      </Box>
      <Paper sx={{ flex: 1, mt: 2, p: 2, overflow: 'hidden', position: 'relative', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
        {fileType === 'text/markdown' ? (
          <ReactMarkdown>{DOMPurify.sanitize(content)}</ReactMarkdown>
        ) : (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                height: '100%',
                width: '100%',
                overflow: 'auto',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <Box
                sx={{
                  minHeight: '100%',
                  minWidth: '100%',
                  width: 'max-content',
                  transform: `scale(${zoomLevel / 100})`,
                  transformOrigin: 'left top',
                  boxSizing: 'content-box',
                  textAlign: justification,
                }}
              >
                <CodeMirror
                  value={content}
                  height="100%"
                  theme={customTheme} // Apply custom theme
                  extensions={extensions}
                  onChange={(value) => dispatch(setContent({ content: value, fileType }))}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Paper>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Upload File</Typography>
          <input type="file" onChange={handleFileUpload} accept=".txt, .json, .md, .js, .xml, .html" />
        </Box>
      </Dialog>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default DocEditor;