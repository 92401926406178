import React from 'react';
import styles from './PricingCard.module.css';

const PricingCard = ({ title, price, billing, buttonText, units, features, onBuyNow }) => {
  return (
    <div className={styles.pricingCard}>
      {title === 'Enterprise' && <span className={styles.star}>★</span>}
      <h4>{title}</h4>
      <div className={styles.pricing}>
        <span className={styles.price}>{price}</span>
        {billing && <span className={styles.billing}>{billing}</span>}
      </div>
      <button className={styles.pricingButton} onClick={onBuyNow}>{buttonText}</button>
      {units && <p className={styles.units}>{units}</p>}
      <ul className={styles.features}>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
};

export default PricingCard;