// src/components/AppCard.js
import React from 'react';
import styles from './AppCard.module.css';

const AppCard = ({ image, title, description }) => {
  return (
    <div className={styles.appCard}>
      <div className={styles.appCardImageContainer}>
        <img src={image} alt={title} className={styles.appCardImage} />
      </div>
      <div className={styles.appCardContent}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AppCard;
