import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, CssBaseline, Button, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/Minimize';
//import AICommExtended from '../Shared/AICommExtended';
//import AISettingsPanel from '../Shared/AISettingsPanel';
//import DocEditor from '../Shared/DocEditor'; // Import the DocEditor component

/**
 * ErrorBoundary component to catch and handle errors in the AppBase component.
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

/**
 * AppBase component serves as the base layout for the application.
 * 
 * @param {string} appId - Unique identifier for the app.
 * @param {string} title - Title to display in the AppBar.
 * @param {string} icon - Icon to display in the AppBar.
 * @param {Array} additionalHeaderItems - Additional items to display in the AppBar.
 * @param {ReactNode} headerContent - Additional content to display in the AppBar.
 * @param {ReactNode} footerContent - Additional content to display in the footer.
 * @param {number} panelCount - Number of panels to display.
 * @param {string} panelOrientation - Orientation of the panels ('horizontal' or 'vertical').
 * @param {Array<number>} panelSizes - Array of sizes for each panel.
 * @param {Array<number>} panelMinSizes - Array of minimum sizes for each panel.
 * @param {boolean} enablePanelResizing - Enable or disable panel resizing.
 * @param {boolean} showPanelBorders - Show or hide panel borders.
 * @param {Array<string>} panelBackgroundColors - Array of background colors for each panel.
 * @param {Array<string>} panelBorders - Array of border styles for each panel.
 * @param {Array<string>} panelPadding - Array of padding values for each panel.
 * @param {Array<string>} panelMargins - Array of margin values for each panel.
 * @param {Array<string>} panelIds - Array of unique IDs for each panel.
 * @param {Array<string>} panelDirection - Array of flex directions for each panel.
 * @param {Array<Array<ReactNode>>} panelContent - Array of arrays of components for each panel.
 * @param {Array<number>} stackSpacing - Array of spacing values between items in each panel.
 * @param {Array<Array<number>>} stackSizes - Array of arrays of sizes for items in each panel.
 * @param {Array<string>} panelClassNames - Array of class names for each panel.
 * @param {Function} minimizeApp - Function to call when minimizing the app.
 * @param {Function} closeApp - Function to call when closing the app.
 * @param {boolean} isDrawerOpen - State of the drawer.
 * @param {boolean} isNavbarExpanded - State of the navbar.
 * @param {Function} onError - Function to call when an error occurs.
 * @param {Object} initialState - Initial state for the component.
 */
const AppBase = ({
  appId,
  title = "Virtual BA",
  icon,
  additionalHeaderItems = [],
  headerContent,
  footerContent,
  panelCount = 3,
  panelOrientation = 'horizontal',
  panelSizes = Array(panelCount).fill(100 / panelCount),
  panelMinSizes = Array(panelCount).fill(10),
  enablePanelResizing = false,
  showPanelBorders = true,
  panelBackgroundColors = Array(panelCount).fill('rgb(40,40,40)'),
  panelBorders = Array(panelCount).fill('1px solid #ccc'),
  panelPadding = Array(panelCount).fill('0'),
  panelMargins = Array(panelCount).fill('0'), // New prop for panel margins
  panelIds = Array(panelCount).fill('').map((_, index) => `panel-${index}`), // New prop for panel IDs
  panelDirection = Array(panelCount).fill('column'), // New prop for panel flex directions
  panelContent = [], // New prop for panel content
  stackSpacing = Array(panelCount).fill(2), // New prop for stack spacing
  stackSizes = [], // New prop for stack sizes
  panelClassNames = [], // New prop for panel class names
  minimizeApp,
  closeApp, // New prop for app closure
  isDrawerOpen,
  isNavbarExpanded,
  onError, // New prop for error handling
  initialState = {}, // New prop for initial state
}) => {
  const [sizes, setSizes] = useState(panelSizes);
  const refs = useRef(Array.from({ length: panelCount }, () => React.createRef()));

  const getPanelSize = (index) => `${sizes[index]}%`;
  const getPanelMinSize = (index) => `${panelMinSizes[index]}%`;
  const getPanelBackgroundColor = (index) => panelBackgroundColors[index];
  const getPanelBorder = (index) => panelBorders[index];
  const getPanelPadding = (index) => panelPadding[index];
  const getPanelMargin = (index) => panelMargins[index];
  const getPanelId = (index) => panelIds[index];
  const getPanelDirection = (index) => panelDirection[index];
  const getStackSpacing = (index) => stackSpacing[index];
  const getStackSizes = (index) => stackSizes[index] || [];
  const getPanelClassName = (index) => panelClassNames[index] || '';

  const handleResize = (index, delta) => {
    setSizes((prevSizes) => {
      const newSizes = [...prevSizes];
      newSizes[index] = Math.max(panelMinSizes[index], prevSizes[index] + delta);
      newSizes[index + 1] = Math.max(panelMinSizes[index + 1], prevSizes[index + 1] - delta);
      return newSizes;
    });
  };

  useEffect(() => {
    setSizes(panelSizes);
  }, [panelSizes]);

  useEffect(() => {
    if (initialState) {
      if (initialState.panelSizes) setSizes(initialState.panelSizes);
      // Add more initial state handling as needed
    }
  }, [initialState]);

  return (
    <ErrorBoundary onError={onError}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', flexGrow: 1, margin: 0, padding: 0 }}>
        <CssBaseline />
        <AppBar position="static">
          <Toolbar>
            {icon && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                <img src={icon} alt={`${title} Icon`} style={{ width: 40, height: 40, marginRight: 5 }} />
                <Typography variant="h6">
                  {title}
                </Typography>
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }}>
              {additionalHeaderItems.map((item, index) => (
                <Box key={index} component="span" sx={{ m: '0 5px' }}>
                  {item}
                </Box>
              ))}
              {headerContent && (
                <Box sx={{ m: '0 5px' }}>
                  {headerContent}
                </Box>
              )}
            </Box>
            <Button color="inherit" onClick={() => console.log('Consultant button clicked')}>
              Consultant
            </Button>
            <IconButton edge="end" color="inherit" onClick={minimizeApp}>
              <MinimizeIcon />
            </IconButton>
            <IconButton edge="end" color="inherit" onClick={closeApp}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: panelOrientation === 'horizontal' ? 'row' : 'column',
            width: '100%',
            padding: 0,
            margin: 0,
            height: '100%',
          }}
        >
          {Array.from({ length: panelCount }, (_, index) => (
            <Box
              key={index}
              ref={refs.current[index]}
              id={getPanelId(index)}
              className={getPanelClassName(index)}
              sx={{
                flexBasis: getPanelSize(index),
                minWidth: panelOrientation === 'horizontal' ? getPanelMinSize(index) : 'auto',
                minHeight: panelOrientation === 'vertical' ? getPanelMinSize(index) : 'auto',
                flexGrow: 0,
                display: 'flex',
                flexDirection: getPanelDirection(index), // Apply the flex direction here
                boxSizing: 'border-box',
                padding: getPanelPadding(index),
                margin: getPanelMargin(index), // Apply the margin here
                border: getPanelBorder(index),
                backgroundColor: getPanelBackgroundColor(index),
                position: 'relative',
              }}
            >
              <Stack direction={getPanelDirection(index)} spacing={getStackSpacing(index)} sx={{ width: '100%', height: '100%' }}>
                {panelContent[index] && panelContent[index].map((Component, compIndex) => (
                  <Box key={compIndex} sx={{ flexGrow: getStackSizes(index)[compIndex] || 1, padding: 0, margin: 0 }}>{Component}</Box>
                ))}
              </Stack>
              {enablePanelResizing && index < panelCount - 1 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: panelOrientation === 'horizontal' ? 0 : 'auto',
                    bottom: panelOrientation === 'horizontal' ? 0 : 'auto',
                    left: panelOrientation === 'horizontal' ? 'auto' : 0,
                    right: panelOrientation === 'horizontal' ? 0 : 'auto',
                    width: panelOrientation === 'horizontal' ? 10 : '100%',
                    height: panelOrientation === 'horizontal' ? '100%' : 10,
                    cursor: panelOrientation === 'horizontal' ? 'ew-resize' : 'ns-resize',
                    zIndex: 1,
                    backgroundColor: 'transparent',
                  }}
                  onMouseDown={(e) => {
                    const startX = e.clientX;
                    const startY = e.clientY;

                    const onMouseMove = (event) => {
                      const deltaX = event.clientX - startX;
                      const deltaY = event.clientY - startY;
                      const delta = panelOrientation === 'horizontal' ? deltaX : deltaY;
                      handleResize(index, delta / refs.current[index].current.offsetWidth * 100);
                    };

                    const onMouseUp = () => {
                      document.removeEventListener('mousemove', onMouseMove);
                      document.removeEventListener('mouseup', onMouseUp);
                    };

                    document.addEventListener('mousemove', onMouseMove);
                    document.addEventListener('mouseup', onMouseUp);
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        {footerContent && (
          <Box sx={{ padding: 2, backgroundColor: '#f1f1f1' }}>
            {footerContent}
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default AppBase;