import React from 'react';
import { AuthProvider, useSession } from '@descope/react-sdk';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import WorkspaceLayout from './components/WorkspaceLayout/WorkspaceLayout';
import DocEditor from './components/Shared/DocEditor';
import AICommExtended from './components/Shared/AICommExtended';
import AppBase from './components/Shared/AppBase';
import AISettingsPanel from './components/Shared/AISettingsPanel';
import AuthPage from './components/AuthPage/AuthPage'; // Import the new AuthPage component
import PurchasePage from './components/PurchasePage/PurchasePage'; // Import the new PurchasePage component

const AuthenticatedApp = () => (
  <div>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/workspace" element={<WorkspaceLayout />} />
      <Route path="/doc-editor" element={<DocEditor />} />
      <Route path="/ai-comm-extended" element={<AICommExtended />} />
      <Route path="/app-base" element={<AppBase />} />
      <Route path="/ai-settings-panel" element={<AISettingsPanel />} />
      <Route path="/PurchasePage" element={<PurchasePage />} />
      {/* Add more routes here as needed */}
    </Routes>
  </div>
);

const AppContent = () => {
  const { isAuthenticated, isSessionLoading } = useSession();

  if (isSessionLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/purchase" element={<PurchasePage />} />
        <Route
          path="*"
          element={
            isAuthenticated ? <AuthenticatedApp /> : <Navigate to="/auth" />
          }
        />
      </Routes>
    </Router>
  );
};

const App = () => (
  <AuthProvider projectId={process.env.REACT_APP_DESCOPE_PROJECT_ID}>
    <AppContent />
  </AuthProvider>
);

export default App;
