import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: '',
  fileType: '',
  zoomLevel: 100,
  history: [],
  future: [],
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setContent(state, action) {
      state.history.push({ content: state.content, fileType: state.fileType });
      state.future = [];
      state.content = action.payload.content;
      state.fileType = action.payload.fileType;
    },
    clearContent(state) {
      state.history.push({ content: state.content, fileType: state.fileType });
      state.future = [];
      state.content = '';
      state.fileType = '';
    },
    setZoomLevel(state, action) {
      state.zoomLevel = action.payload;
    },
    undo(state) {
      if (state.history.length > 0) {
        const lastState = state.history.pop();
        state.future.push({ content: state.content, fileType: state.fileType });
        state.content = lastState.content;
        state.fileType = lastState.fileType;
      }
    },
    redo(state) {
      if (state.future.length > 0) {
        const nextState = state.future.pop();
        state.history.push({ content: state.content, fileType: state.fileType });
        state.content = nextState.content;
        state.fileType = nextState.fileType;
      }
    },
  },
});

export const { setContent, clearContent, setZoomLevel, undo, redo } = documentSlice.actions;
export default documentSlice.reducer;