import React from 'react';
import { Box, Typography } from '@mui/material';

const PurchasePage = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#ffffff' }}>
      <Typography component="h1" variant="h5">
        Purchase Page Placeholder
      </Typography>
    </Box>
  );
};

export default PurchasePage;