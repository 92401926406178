import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  addConversationBox,
  deleteConversationBox,
  lockConversation,
  unlockConversation,
  updateConversationBoxContent,
  setInitialConversationBoxes,
} from '../../features/aiConversationSlice';
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import {
  ContentCopy as CopyIcon,
  Delete as TrashIcon,
  Mic as MicrophoneIcon,
} from '@mui/icons-material';

const AICommExtended = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const conversationBoxes = useSelector((state) => state.aiConversation.conversationBoxes);
  const isLocked = useSelector((state) => state.aiConversation.isLocked);
  const containerRef = useRef(null);

  const handleSubmit = () => {
    if (!isLocked) {
      dispatch(lockConversation());
      setTimeout(() => {
        const newId = conversationBoxes.length + 1;
        dispatch(addConversationBox({ id: newId, type: 'VirtualBA', content: 'AI Response' }));
        dispatch(addConversationBox({ id: newId + 1, type: 'User', content: '' }));
        dispatch(unlockConversation());
      }, 1000);
    }
  };

  const handleDelete = (id) => {
    const userBoxesWithContent = conversationBoxes.filter(box => box.type === 'User' && box.content.trim() !== '');
    const boxToDelete = conversationBoxes.find(box => box.id === id);

    if (boxToDelete.type === 'User' && boxToDelete.content.trim() === '') {
      dispatch(deleteConversationBox(id));
    } else if (boxToDelete.type === 'User' && userBoxesWithContent.length > 1) {
      dispatch(deleteConversationBox(id));
    } else if (boxToDelete.type === 'VirtualBA') {
      dispatch(deleteConversationBox(id));
    } else {
      alert('Cannot delete the last User text area with saved content.');
    }
  };

  const handleContentChange = (id, content) => {
    dispatch(updateConversationBoxContent({ id, content }));
  };

  const handleTextareaInput = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 'px';
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      alert('Text copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  useEffect(() => {
    dispatch(setInitialConversationBoxes([
      { id: 1, type: 'System', content: '' },
      { id: 2, type: 'User', content: '' },
    ]));
  }, [dispatch]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [conversationBoxes]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: 2,
        borderRadius: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '90vh',
        width: '100%',
        boxSizing: 'border-box',
      }}
      ref={containerRef}
    >
      {conversationBoxes.map((box) => (
        <Paper
          key={box.id}
          sx={{
            padding: 2,
            borderRadius: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="subtitle2" sx={{ marginBottom: 1, color: theme.palette.text.secondary }}>
            {box.type === 'VirtualBA' ? 'Assistant' : box.type}
          </Typography>
          <TextField
            value={box.content}
            onChange={(e) => handleContentChange(box.id, e.target.value)}
            onInput={handleTextareaInput}
            multiline
            variant="outlined"
            sx={{
              width: '100%',
              marginBottom: 1,
              borderRadius: 1,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
              },
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
          <Box sx={{ display: 'flex', gap: 1, position: 'absolute', right: 16, top: 16 }}>
            <IconButton size="small" sx={{ color: theme.palette.text.primary }}>
              <MicrophoneIcon fontSize="small" />
            </IconButton>
            <IconButton size="small" sx={{ color: theme.palette.text.primary }} onClick={() => handleCopy(box.content)}>
              <CopyIcon fontSize="small" />
            </IconButton>
            {(box.type === 'User' || box.type === 'VirtualBA') && (
              <IconButton size="small" sx={{ color: theme.palette.text.primary }} onClick={() => handleDelete(box.id)}>
                <TrashIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Paper>
      ))}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isLocked || !conversationBoxes.some(box => box.type === 'User' && box.content.trim() !== '')}
        sx={{ marginTop: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default AICommExtended;