import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import HeroSection from './HeroSection';
import AboutUsSection from './AboutUsSection';
import ApplicationsSection from './ApplicationsSection';
import PricingSection from './PricingSection';
import ContactSection from './ContactSection';
import styles from './LandingPage.module.css';

const LandingPage = ({ onAuthOpen }) => {
  const navigate = useNavigate();
  const [selectedSection, setSelectedSection] = useState('hero');
  const [apiResponse, setApiResponse] = useState(null);

  const handleAuthRedirect = (isPurchase) => {
    navigate('/auth', { state: { isPurchase } });
  };

  const handleApiCall = async () => {
    try {
      const response = await fetch('https://b8d8n9f3kb.execute-api.us-east-1.amazonaws.com/prod/authorize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: 'Hello From SysGen' }),
      });
      
      const data = await response.json();
      console.log('API Response:', data);
      setApiResponse(data);
    } catch (error) {
      console.error('Error calling API:', error);
      setApiResponse({ error: error.message });
    }
  };

  const renderSection = () => {
    switch (selectedSection) {
      case 'hero':
        return <HeroSection onAuthOpen={() => handleAuthRedirect(false)} />;
      case 'about':
        return <AboutUsSection />;
      case 'applications':
        return <ApplicationsSection />;
      case 'pricing':
        return <PricingSection onBuyNow={() => handleAuthRedirect(true)} />;
      case 'contact':
        return <ContactSection />;
      default:
        return <HeroSection onAuthOpen={() => handleAuthRedirect(false)} />;
    }
  };

  return (
    <div className={styles.landingPage}>
      <Header onAuthOpen={() => handleAuthRedirect(false)} setSelectedSection={setSelectedSection} />
      {renderSection()}
      
      {/* Temporary button to trigger the API call */}
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <button 
          onClick={handleApiCall} 
          style={{ 
            padding: '10px 20px', 
            fontSize: '16px', 
            backgroundColor: '#4CAF50', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer'
          }}>
          Call API
        </button>
      </div>
      
      {apiResponse && (
        <div style={{ padding: '20px', textAlign: 'center', color: '#333' }}>
          <h3>API Response:</h3>
          <pre>{JSON.stringify(apiResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
