import { createSlice } from '@reduxjs/toolkit';

export const aiConversationSlice = createSlice({
  name: 'aiConversation',
  initialState: {
    conversationBoxes: [
      { id: 1, type: 'System', content: '' },
      { id: 2, type: 'User', content: '' },
    ],
    isLocked: false,
  },
  reducers: {
    setInitialConversationBoxes: (state, action) => {
      state.conversationBoxes = action.payload;
    },
    addConversationBox: (state, action) => {
      state.conversationBoxes.push(action.payload);
    },
    deleteConversationBox: (state, action) => {
      state.conversationBoxes = state.conversationBoxes.filter(box => box.id !== action.payload);
    },
    lockConversation: (state) => {
      state.isLocked = true;
    },
    unlockConversation: (state) => {
      state.isLocked = false;
    },
    updateConversationBoxContent: (state, action) => {
      const { id, content } = action.payload;
      const box = state.conversationBoxes.find(box => box.id === id);
      if (box) {
        box.content = content;
      }
    },
  },
});

export const {
  setInitialConversationBoxes,
  addConversationBox,
  deleteConversationBox,
  lockConversation,
  unlockConversation,
  updateConversationBoxContent,
} = aiConversationSlice.actions;

export default aiConversationSlice.reducer;
