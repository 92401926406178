// File: src/components/LandingPage/AboutUsSection.js

import React from 'react';
import styles from './AboutUsSection.module.css';

const AboutUsSection = () => {
  return (
    <section className={styles.aboutUsSection} id="about">
      <h1>About Our Company</h1>
      <h2>About Us</h2>
      <p>
        We are a dedicated team focused on delivering the best solutions for your business. Our expertise spans various domains, ensuring that we provide comprehensive services tailored to your needs.
      </p>
      <h2>Our Team</h2>
      <p>
        Our team consists of experienced professionals who are passionate about what they do. We work collaboratively to achieve our goals and continuously strive to improve.
      </p>
      <h2>The Technology</h2>
      <p>
        We leverage the latest technologies to deliver high-quality solutions. Our stack includes modern frameworks and tools that ensure scalability, performance, and security. 
        We constantly stay updated with the industry trends and incorporate best practices in our development process.
        Our technology enables us to build robust applications that meet the diverse needs of our clients.
        From frontend development using React to backend solutions with Node.js and databases like MongoDB and SQL, we cover all aspects of web development.
        Additionally, we utilize cloud services like AWS and Azure to deploy and manage our applications efficiently.
        Our continuous integration and deployment pipelines ensure that we deliver updates quickly and reliably.
        We also prioritize user experience and accessibility, making sure our applications are easy to use and accessible to all users.
        Our commitment to quality and innovation drives us to explore new technologies and implement them in our projects.
        With a focus on security, we follow best practices to protect our applications from vulnerabilities and threats.
        We also provide ongoing support and maintenance to ensure our solutions remain up-to-date and performant.
        In summary, our technology stack and practices enable us to deliver exceptional digital solutions that drive success for our clients.
      </p>
    </section>
  );
};

export default AboutUsSection;
