import React from 'react';
import styles from './Header.module.css';
import logo from '../../assets/logo.png';

const Header = ({ onAuthOpen, setSelectedSection }) => {
  return (
    <header className={styles.header}>
      <a href="#top" className={styles.logo} onClick={() => setSelectedSection('hero')}>
        <img src={logo} alt="SysGen Logo" className={styles.logoImg} />
      </a>
      <nav className={styles.nav}>
        <ul>
          <li><button onClick={() => setSelectedSection('applications')}>Applications</button></li>
          <li><button onClick={() => setSelectedSection('pricing')}>Pricing</button></li>
          <li><button onClick={() => setSelectedSection('about')}>About Us</button></li>
          <li><button onClick={() => setSelectedSection('contact')}>Contact</button></li>
        </ul>
      </nav>
      <div className={styles.authButtons}>
        <button onClick={onAuthOpen}>Login / Sign Up</button>
      </div>
    </header>
  );
};

export default Header;