import React from 'react';
import AppCard from './AppCard';
import styles from './ApplicationsSection.module.css';

const ApplicationsSection = () => {
  const apps = [
    { id: 1, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image1.png' },
    { id: 2, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image2.png' },
    { id: 3, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image3.png' },
    { id: 4, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image4.png' },
    { id: 5, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image5.png' },
    { id: 6, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image6.png' },
    { id: 7, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image7.png' },
    { id: 8, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image2.png' },
    { id: 9, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image3.png' },
    { id: 10, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image4.png' },
    { id: 11, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image5.png' },
    { id: 12, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image6.png' },
    { id: 13, name: 'App Name', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.', image: 'path/to/image7.png' },
  ];

  return (
    <section className={styles.applicationsSection} id="applications">
      <h2> </h2>
      <div className={styles.appCardsContainer}>
        {apps.map(app => (
          <AppCard key={app.id} name={app.name} description={app.description} image={app.image} />
        ))}
      </div>
    </section>
  );
};

export default ApplicationsSection;